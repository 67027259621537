import React, { Component } from "react";
import { UserManagerService } from "Services";
import _ from "lodash";
import { Dropdown } from "Component";
import { DropdownItemProps } from "semantic-ui-react";

export default class PartnerDropdown extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      options: [] as DropdownItemProps[],
      selected: [] as DropdownItemProps[],
      searchQuery: "",
      companies: [] as Company[],
      open: false
    };
    this.searchCompaniesDebounced = _.debounce(this.searchCompanies, 500);
  }

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (!_.isEqual(this.props.system, prevProps.system)) {
      this.setState({
        options: [],
        selected: []
      });
    }
    if (!_.isEqual(this.props.initialCompanies, prevProps.initialCompanies)) {
      const options = this.createOptions(this.props.initialCompanies);
      this.setState({
        options,
        selected: options.filter(item =>
          _.includes(this.props.value, item.value)
        )
      });
    }
  };

  createOptions = (companies: Company[]) => {
    return companies.map((c: Company, i: number) => {
      return {
        key: i,
        text: `${c.FullName} (${c.PartnerCode})`,
        value: c.PartnerCode
      } as DropdownItemProps;
    });
  };

  // Holds the debounced search function
  searchCompaniesDebounced = (value: string) => {};

  searchCompanies = (value: string) => {
    const { userID, system } = this.props;
    const { selected, searchQuery } = this.state;

    this.setState({ loading: true });
    UserManagerService.searchUserCompanies(
      userID,
      value,
      system.subsys === 1045 ? null : 1044
    )
      .then(data => {
        if (value !== searchQuery) {
          return;
        }
        this.setState({ companies: data });
        let options = this.createOptions(data);

        options = options.concat(
          selected.map((item: DropdownItemProps, i: number) => {
            item.key = options.length + i;
            return item;
          })
        );

        this.setState({ options, open: true });
      })
      .catch(() => {
        this.setState({ options: [...selected], open: false });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleSearchChange = (value: string) => {
    const { selected } = this.state;
    this.setState({ searchQuery: value });
    if (value.length > 3) {
      this.searchCompaniesDebounced(value);
    } else {
      this.setState({ options: [...selected], loading: false, open: false });
    }
  };

  handleChange = (value: string[]) => {
    const { onChange } = this.props;
    const { selected, options } = this.state;

    const newValues = value.filter(v => {
      return (
        selected.findIndex((item: DropdownItemProps) => item.value === v) === -1
      );
    });
    const removedValues = selected
      .filter((item: DropdownItemProps) => !_.includes(value, item.value))
      .map((item: DropdownItemProps) => item.value);

    const newSelected = newValues.map((v: string, i: number) => {
      const option = options.find(
        (item: DropdownItemProps) => item.value === v
      );
      if (option) {
        return _.clone(option);
      }
    });

    const updatedSelected = selected.filter(
      (item: DropdownItemProps) => !_.includes(removedValues, item.value)
    );

    this.setState({
      selected: [...updatedSelected, ...newSelected],
      open: updatedSelected.length + newSelected.length !== options.length
    });

    onChange(value);
  };

  handleClose = () => this.setState({ open: false, searchQuery: "" });

  handleOpen = () =>
    this.setState({
      open: this.state.selected.length !== this.state.options.length
    });

  handleEnterPress = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (this.state.searchQuery.length > 0) {
        this.searchCompanies(this.state.searchQuery);
      }
    }
  };

  handleSearchIconClick = () => {
    if (this.state.searchQuery.length > 0) {
      this.searchCompanies(this.state.searchQuery);
    }
  };

  search = (items: any[], searchString: string) => {
    const searchStringLower = searchString.toLowerCase();
    const partnerCodes = this.state.companies
      // The search constraints are the same as the backend search for consistency
      // SapId and CompanyRegNo are both numbers so they don't need to be cast to lowercase
      .filter((item: Company) => {
        return (
          (item.FullName
            ? item.FullName.toLowerCase().includes(searchStringLower)
            : false) ||
          (item.PartnerCode
            ? item.PartnerCode.toLowerCase().startsWith(searchStringLower)
            : false) ||
          (item.SapID ? item.SapID === searchString : false) ||
          (item.CompanyRegNo
            ? item.CompanyRegNo.startsWith(searchString)
            : false)
        );
      })
      .map((item: Company) => item.PartnerCode);
    return items.filter(item => _.includes(partnerCodes, item.value));
  };

  render = () => {
    const { userID, system, onChange, initialCompanies, ...props } = this.props;
    const { selected, ...state } = this.state;

    return (
      <Dropdown
        onSearchChange={(event: any, data: any) => {
          this.handleSearchChange(data.searchQuery);
        }}
        onChange={(value: string[]) => this.handleChange(value)}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
        icon={
          this.state.options.length !== selected.length
            ? "dropdown"
            : { name: "search", onClick: this.handleSearchIconClick }
        }
        search={this.search}
        onKeyDown={this.handleEnterPress}
        {...state}
        {...props}
      />
    );
  };
}
