import React, { useState, useEffect } from "react";
import { DropdownItemPropsSearch, Hub } from "./interfaces";
import { DomesticService, languages } from "Services";
import styles from "./index.less";
import * as _ from "lodash";

import { Dropdown } from "Component";
import { DropdownItemProps } from "semantic-ui-react";

export default (props: any) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([] as DropdownItemProps[]);

  useEffect(() => {
    getHubs();
  }, []);

  const getHubs = () => {
    setLoading(true);
    DomesticService.getHubs().then((hubs: any) => {
      setOptions(
        hubs.map((item: Hub, i: number) => {
          return {
            key: i,
            text: `(${item.HubId}) ${item.Description}`,
            value: item.HubId,
            searchvalues: [item.HubId, item.Description]
          } as DropdownItemPropsSearch;
        })
      );
      setLoading(false);
    });
  };

  const searchForHub = (
    hubs: DropdownItemPropsSearch[],
    searchString: string
  ) => {
    return hubs.filter(item => {
      for (const value of item.searchvalues) {
        if (value && value.toLowerCase().includes(searchString.toLowerCase())) {
          return item;
        }
      }
    });
  };

  const { error, className, ...rest } = props;

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <Dropdown
        placeholder={languages("LABEL_SELECTLOCATION")}
        options={options}
        search={searchForHub}
        selectOnNavigation={false}
        selectOnBlur={true}
        loading={loading}
        multiple={true}
        error={error !== undefined}
        {...rest}
      />
      <span className={"error-message"}>
        {error && languages(error.message)}
      </span>
    </div>
  );
};
