import * as angular from "angular";
import { $uiRouter } from "Bootstrap/angular";
import { Transition } from "@uirouter/core";
import UserManagerController from "UserManager/UserManagerController";
import * as userManagerTemplate from "UserManager/userManager.html";
import { USER_ROLES } from "Constants/UserRoles";
import CreateRequestedUserModal from "./createRequestedUserModal";
import { TranslationService } from "Services";
import { react2angular } from "react2angular";

import UserModal from "./UserModal";

export const MAIN_STATE: string = "usermanager";

class UserManager {
  constructor() {
    const routes: any[] = [
      {
        name: `${MAIN_STATE}`,
        url: `/${MAIN_STATE}`,
        parent: "masterPage",
        template: `${userManagerTemplate}`,
        controller: UserManagerController,
        data: { roles: [USER_ROLES.USERMANAGER] }
      },
      {
        name: `requesteduser`,
        url: `/requesteduser`,
        parent: "masterPage",
        component: CreateRequestedUserModal,
        onEnter: [
          "$transition$",
          ($transition$: Transition) => {
            const urlParams = new URLSearchParams(window.location.search);
            const lang = urlParams.get("lang");
            if (lang) {
              TranslationService.switchLanguage(lang);
            }
          }
        ],
        data: {
          roles: [],
          noLogin: true
        }
      }
    ];

    routes.forEach(item => $uiRouter.stateRegistry.register(item));
  }
}

angular
  .module("serviceWebApp")
  .run(UserManager)
  .component(
    "userModal",
    react2angular(UserModal, ["open", "onClose", "onSubmit", "user", "action"])
  );
