import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Form,
  Button,
  Grid,
  GridColumn as Column,
  GridRow as Row,
  Loader,
} from "semantic-ui-react";
import { Input, Radio, Dropdown, Checkbox } from "Component";
import {
  Roles,
  PartnerDropdown,
  UsernameInput,
  AgentAccess,
} from "./Components";
import {
  languages,
  SamskipNotify,
  ShipmentService,
  TranslationService,
  UserManagerService,
  UserService,
} from "Services";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import styles from "./index.less";
import { USER_CREATION_ACTIONS } from "Constants/UserCreationConstants";
import { UtilityFunctions } from "Utilities";

declare var GlobalConfig: GlobalConfig;

interface UserModalFormProps {
  onClose: Function;
  user: UserViewModel | null;
  action: USER_CREATION_ACTIONS;
}

const createValidationSchema = (action: USER_CREATION_ACTIONS) => {
  return yup.object().shape({
    Type: yup.string().required(),
    LoginID: yup.object({
      value: yup.string().required(),
      exists:
        action !== USER_CREATION_ACTIONS.EDIT
          ? yup.boolean().oneOf([false], "ERROR_USERNAME_EXISTS")
          : yup.boolean().optional(),
    }),
    Username: yup.string().required(),
    Email: yup.string().email().required(),
    Password: yup.string().when("Type", {
      is: (value) => value !== "E" && action === USER_CREATION_ACTIONS.CREATE,
      then: yup.string().required(),
    }),
    Password2: yup
      .string()
      .oneOf([yup.ref("Password"), undefined], "ERROR_PASSWORD_MISMATCH")
      .when("Type", {
        is: (value) => value !== "E" && action === USER_CREATION_ACTIONS.CREATE,
        then: yup.string().required(),
      }),
    Partners: yup.array().when("Type", {
      is: (value) => value === "C",
      then: yup.array().min(1),
    }),
    Roles: yup.array(),
    Hubs: yup.array().when(["Type", "Roles"], {
      is: (type, roles) => type === "A" && _.includes(roles, 1002),
      then: yup.array().min(1, "ERROR_HUBS_REQUIRED"),
    }),
  });
};

// TODO: Fetch from API
const systems = [
  {
    name: "Samskip",
    subsys: 1045,
    sitesubsys: GlobalConfig.subSys,
  },
  {
    name: "Jónar Transport",
    subsys: 1044,
    sitesubsys: 1049,
  },
];

const setValueOptions = {
  shouldDirty: false,
  shouldValidate: true,
};

export default (props: UserModalFormProps) => {
  const { user, action } = props;
  const {
    handleSubmit,
    errors,
    control,
    formState,
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm({
    mode: "all",
    resolver: yupResolver(createValidationSchema(action)),
    defaultValues: {
      Type: "C",
      LoginID: { value: "", exists: undefined },
      Username: "",
      Email: "",
      Password: "",
      Password2: "",
      Notifications: [] as string[],
      System: 1000,
      Departments: ["SOTT"],
      Partners: [],
      Language: "is",
      Status: [],
      Roles: [],
      Hubs: [],
    },
  });

  const [departments, setDepartments] = useState([] as Department[]);
  const [translations, setTranslations] = useState([] as Translation[]);
  const [roles, setRoles] = useState([] as WebRole[]);
  const [loading, setLoading] = useState({ active: false, message: "" });
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [companies, setCompanies] = useState([] as Company[]);
  const currentUser = UserService.getUserProfile();

  useEffect(() => {
    // Rerender when type or system changes
    watch("Type");
    watch("System");

    const promises = [];

    setLoading({ active: true, message: "" });
    promises.push(ShipmentService.getDepartments());
    promises.push(TranslationService.getAvailableTranslations());

    const profile = UserService.getUserProfile();
    if (profile) {
      promises.push(
        UserManagerService.getWebRoles(profile.User.ID, profile.Access.SubSys)
      );
    }

    if (user && action === USER_CREATION_ACTIONS.EDIT) {
      setLoading({
        active: true,
        message: languages("TEXT_FETCHING_USER_DATA"),
      });
      promises.push(
        UserManagerService.getUserAccess(
          user.UserID,
          user.SubSys
        ) as SamskipPromise<any>
      );
    }

    Promise.all(promises)
      .then((values: any[]) => {
        setDepartments(values[0] as Department[]);
        setTranslations(values[1] as Translation[]);
        // WebRoles should always get a value
        values[2] && setRoles(values[2] as WebRole[]);
        values[3] && populateWithUser(values[3] as UserManagerUserAccess);
        setLoading({ active: false, message: "" });
      })
      .catch(() => {
        SamskipNotify.displayError(languages("ERROR_FETCHING_DATA"));
        props.onClose();
      });
  }, []);

  useEffect(() => {
    setValue("Partners", []);
  }, [getValues("System")]);

  const populateWithUser = (userAccess: UserManagerUserAccess) => {
    setValue("Type", userAccess.Access.UserType, setValueOptions);
    setValue("LoginID", { value: userAccess.Access.LoginID }, setValueOptions);
    setValue("Username", userAccess.User.Username, setValueOptions);
    setValue("Email", userAccess.Access.Email, setValueOptions);
    const notifications = [
      userAccess.Access.SendMail === "Y" ? "email" : null,
      userAccess.Access.SendMessage === "Y" ? "phone" : null,
    ] as string[];
    setValue(
      "Notifications",
      notifications.filter((item) => item !== null),
      setValueOptions
    );
    setValue("System", userAccess.Access.SubSys, setValueOptions);
    setValue(
      "Departments",
      userAccess.Departments.filter((v, i, a) => a.indexOf(v) === i),
      setValueOptions
    );
    setValue(
      "Partners",
      userAccess.Companies.map((item) => item.PartnerCode),
      setValueOptions
    );
    setCompanies(userAccess.Companies);
    setValue("Language", userAccess.User.Language, setValueOptions);
    const statuses = [
      userAccess.User.Disabled === "Y" ? "disabled" : null,
      userAccess.User.Locked === "Y" ? "locked" : null,
    ] as string[];
    setValue(
      "Status",
      statuses.filter((item) => item !== null),
      setValueOptions
    );
    setValue("Hubs", userAccess.Hubs ? userAccess.Hubs : [], setValueOptions);
    setValue("Roles", userAccess.Roles, setValueOptions);
    if (userAccess.Hubs) {
      // Needed to trigger validation after the hub component is loaded
      window.setTimeout(() => trigger("Hubs"), 100);
    }
  };

  const renderRow = (
    label: string,
    field: string,
    controllerProps: any,
    columnStyles?: string,
    showErrorForType?: string,
    showErrorForProperty?: string,
    hidden?: boolean
  ) => {
    const renderErrorMessage = (
      field: string,
      type?: string,
      property?: string
    ) => {
      const error = _.get(errors, `${field}${property ? `.${property}` : ""}`);
      if (error) {
        if (type && error.type !== type) {
          return;
        }
        return <p className="text-danger">{languages(error.message)}</p>;
      }
    };

    const createController = (name: string, additionalProps: any) => {
      return (
        <Controller
          control={control}
          name={name}
          id={name}
          // Chrome is very weird about autofill so this is the only value that works
          autoComplete="new-password"
          error={_.get(errors, name) !== undefined}
          {...additionalProps}
        />
      );
    };

    return (
      <Row className={`u-flexAlignItemsCenter ${hidden ? "u-hidden" : ""}`}>
        <Column width={4}>
          {
            <label htmlFor={field} className="label u-margin0">
              {label}
            </label>
          }
        </Column>
        <Column className={columnStyles} width={12}>
          {createController(field, controllerProps)}
          {(showErrorForType || showErrorForProperty) &&
            renderErrorMessage(field, showErrorForType, showErrorForProperty)}
        </Column>
      </Row>
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer">
        <div className="left">
          <Button onClick={() => props.onClose(false)}>
            {languages("LABEL_CANCEL")}
          </Button>
        </div>
        <div className="right">
          {action === USER_CREATION_ACTIONS.EDIT && (
            <Button
              className="negative"
              disabled={submitting || deleting}
              onClick={onDelete}
              type="button"
            >
              {deleting ? (
                <Loader active inline="centered" inverted />
              ) : (
                languages("LABEL_DELETE_USER")
              )}
            </Button>
          )}
          <Button
            className="primary"
            disabled={
              !formState.isValid || !formState.isDirty || deleting || submitting
            }
          >
            {submitting ? (
              <Loader active inline="centered" inverted />
            ) : (
              languages(
                `${
                  action === USER_CREATION_ACTIONS.CREATE
                    ? "LABEL_CREATEUSER"
                    : action === USER_CREATION_ACTIONS.EDIT
                    ? "LABEL_CHANGEUSER"
                    : "LABEL_CREATE_USER_REQUEST"
                }`
              )
            )}
          </Button>
        </div>
      </div>
    );
  };

  const displayMessageAndClose = (key: string, username: string) => {
    SamskipNotify.displaySuccess(
      TranslationService.translate(key, {
        username,
      }),
      undefined,
      "body"
    );
    window.setTimeout(() => {
      props.onClose(true);
    }, 0);
  };

  const onSubmit = (values: any) => {
    const system = systems.find((item) => item.sitesubsys === values.System);
    const vm = {
      User: {
        ID: user ? user.UserID : null,
        Username: values.Username.trim(),
        Language: values.Language,
        Disabled: _.includes(values.Status, "disabled") ? "Y" : "N",
        Locked: _.includes(values.Status, "locked") ? "Y" : "N",
        SendEmail: _.includes(values.Notifications, "email") ? "Y" : "N",
        SendMessage: _.includes(values.Notifications, "phone") ? "Y" : "N",
      } as User,
      Access: {
        Email: values.Email,
        LoginID: values.LoginID.value.trim(),
        UserType: values.Type,
        SubSys: values.System,
        SendMail: _.includes(values.Notifications, "email") ? "Y" : "N",
        SendMessage: _.includes(values.Notifications, "phone") ? "Y" : "N",
        Password: values.Password,
      } as UserAccess,
      Roles: values.Roles,
      Companies:
        values.Partners &&
        values.Partners.map((item: string) => {
          return {
            PartnerCode: item,
            CompanySubSys: system ? system.subsys : null,
          } as Company;
        }),
      Departments:
        values.Departments &&
        values.Departments.map((item: string) => {
          return { Department: item } as Department;
        }),
      Hubs: values.Hubs,
    } as CreateUpdateUserViewModel;

    setSubmitting(true);
    if (action === USER_CREATION_ACTIONS.CREATE) {
      UserManagerService.createUser(vm)
        .then((data: string) => {
          if (data.toLowerCase() === "user created") {
            displayMessageAndClose("TEXT_USER_CREATED", values.Username);
          }
        })
        .catch((ex: any) => {
          SamskipNotify.displayError(languages("ERROR_CREATEUSER"));
          setSubmitting(false);
        });
    } else if (action === USER_CREATION_ACTIONS.EDIT) {
      UserManagerService.updateUser(vm)
        .then((data: string) => {
          if (data.indexOf("updated") > -1) {
            displayMessageAndClose("TEXT_USER_CHANGED", values.Username);
          }
        })
        .catch(() => {
          SamskipNotify.displayError(languages("ERROR_UPDATEUSER"));
          setSubmitting(false);
        });
    } else if (action === USER_CREATION_ACTIONS.REQUEST) {
      UserManagerService.userRequest(vm)
        .then((data: string) => {
          if (data === "user request created") {
            displayMessageAndClose(
              "TEXT_USER_REQUEST_CREATED",
              values.Username
            );
          }
        })
        .catch(() => {
          SamskipNotify.displayError(languages("ERROR_CREATEUSERREQUEST"));
          setSubmitting(false);
        });
    }
  };

  const onDelete = () => {
    if (user) {
      const modalInstance = UtilityFunctions.confirmModal(
        TranslationService.translate("TEXT_CONFIRM_DELETE_USER", {
          user: user.LoginID,
        }),
        "TEXT_IRREVERSIBLE_ACTION",
        "LABEL_CANCEL",
        "LABEL_PROCEED"
      );
      modalInstance.then(() => {
        setDeleting(true);
        UserManagerService.deleteUser(user.UserID)
          .then(() => {
            displayMessageAndClose("TEXT_USER_DELETED", user.UserName);
          })
          .catch(() => {
            SamskipNotify.displayError(languages("ERROR_DELETEUSER"));
            setDeleting(false);
          });
      });
    }
  };

  const isCustomer = () => {
    const type = getValues("Type");
    return type === "C";
  };

  const isEmployee = () => {
    const type = getValues("Type");
    return type === "E";
  };

  const isAgent = () => {
    const type = getValues("Type");
    return type === "A";
  };

  const isEmployeeOrAgent = () => {
    const type = getValues("Type");
    return type === "E" || type === "A";
  };

  const getAvailableTypes = () => {
    const types = [];
    types.push({ text: languages("LABEL_CUSTOMER"), value: "C" });
    if (action !== USER_CREATION_ACTIONS.REQUEST) {
      types.push({ text: languages("LABEL_EMPLOYEE"), value: "E" });
    }
    types.push({ text: languages("LABEL_AGENT"), value: "A" });
    return types;
  };

  return (
    <>
      {loading.active && (
        <Loader
          active
          size="massive"
          content={loading.message}
          inline="centered"
        />
      )}
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={`${styles.form} ${loading.active ? "u-hidden" : ""}`}
      >
        <Grid>
          {renderRow(
            languages("LABEL_SETTINGS"),
            "Type",
            {
              as: Radio,
              data: getAvailableTypes(),
            },
            "u-flex u-flexAlignItemsBaseline"
          )}
          {renderRow(
            languages("LABEL_USERNAME"),
            "LoginID",
            {
              as: UsernameInput,
              placeholder: languages("LABEL_USERNAME"),
              disabled: action === USER_CREATION_ACTIONS.EDIT,
            },
            undefined,
            undefined,
            "exists"
          )}
          {renderRow(languages("LABEL_NAME"), "Username", {
            as: Input,
            placeholder: languages("LABEL_NAME"),
          })}
          {renderRow(languages("LABEL_EMAIL"), "Email", {
            as: Input,
            placeholder: languages("LABEL_EMAIL"),
          })}
          {!isEmployee() &&
            action !== USER_CREATION_ACTIONS.REQUEST &&
            renderRow(languages("LABEL_PASSWORD"), "Password", {
              as: Input,
              placeholder: languages("LABEL_PASSWORD"),
              type: "password",
            })}
          {!isEmployee() &&
            action !== USER_CREATION_ACTIONS.REQUEST &&
            renderRow(
              languages("LABEL_PASSWORDAGAIN"),
              "Password2",
              {
                as: Input,
                placeholder: languages("LABEL_PASSWORDAGAIN"),
                type: "password",
              },
              undefined,
              "oneOf"
            )}
          {renderRow(
            languages("LABEL_NOTIFICATION"),
            "Notifications",
            {
              render: ({ value, ...rest }: any) => (
                <Checkbox
                  values={value}
                  onChange={(newValue: any) => {
                    setValue("Notifications", newValue);
                  }}
                  data={[
                    {
                      text: languages("LABEL_SEND_NOTIFICATIONS_EMAIL"),
                      value: "email",
                    },
                    {
                      text: languages("LABEL_SEND_NOTIFICATIONS_CELLPHONE"),
                      value: "phone",
                    },
                  ]}
                  {...rest}
                />
              ),
            },
            "u-flex u-flexAlignItemsBaseline u-flexWrap"
          )}
          {renderRow(
            languages("LABEL_SYSTEM"),
            "System",
            action === USER_CREATION_ACTIONS.EDIT
              ? {
                  render: ({ value, ...rest }: any) => {
                    const system = systems.find(
                      (item) => item.sitesubsys === value
                    );
                    return (
                      <Input
                        disabled={true}
                        value={system ? system.name : ""}
                      />
                    );
                  },
                }
              : {
                  as: Radio,
                  data: systems.map((system) => {
                    return {
                      text: system.name,
                      value: system.sitesubsys,
                    };
                  }),
                },
            action === USER_CREATION_ACTIONS.EDIT
              ? ""
              : "u-flex u-flexAlignItemsBaseline"
          )}
          {renderRow(languages("LABEL_DEPARTMENTS"), "Departments", {
            as: Dropdown,
            options: departments.map((department) => {
              return {
                text: languages(department.DepartmentName),
                value: department.Department,
              };
            }),
            placeholder: languages("LABEL_CHOOSE_DEPARTMENTS"),
            multiple: true,
            search: true,
          })}
          {renderRow(
            languages("LABEL_COMPANY"),
            "Partners",
            {
              as: PartnerDropdown,
              placeholder: "PartnerCode, Nafn, KT, SapID",
              multiple: true,
              userID: currentUser ? currentUser.User.ID : null,
              system: systems.find((i) => i.sitesubsys === getValues("System")),
              initialCompanies:
                action === USER_CREATION_ACTIONS.EDIT ? companies : [],
            },
            "u-flex u-flexAlignItemsBaseline",
            undefined,
            undefined,
            !isCustomer()
          )}
          {renderRow(
            languages("LABEL_LANGUAGE"),
            "Language",
            {
              as: Radio,
              data: translations.map((item) => {
                return {
                  text: languages(
                    `LABEL_LANGUAGE_${item.Language.toUpperCase()}`
                  ),
                  value: item.Language,
                };
              }),
            },
            "u-flex u-flexAlignItemsBaseline"
          )}
          {renderRow(
            languages("LABEL_STATUS"),
            "Status",
            {
              render: ({ value, ...rest }: any) => (
                <Checkbox
                  values={value}
                  onChange={(newValue: any) => {
                    setValue("Status", newValue);
                  }}
                  data={[
                    {
                      text: languages("LABEL_DISABLED"),
                      value: "disabled",
                    },
                    {
                      text: languages("LABEL_LOCKED"),
                      value: "locked",
                    },
                  ]}
                  {...rest}
                />
              ),
            },
            "u-flex u-flexAlignItemsBaseline u-flexWrap"
          )}
        </Grid>
        <Controller
          control={control}
          name={"Roles"}
          render={({ value, onChange, ...rest }) => (
            <Roles
              roles={roles}
              showEmployeeRoles={isEmployeeOrAgent()}
              selectedRoles={value}
              error={_.get(errors, "Roles") !== undefined}
              onChange={(value: any) => {
                onChange(value);
                if (_.includes(value, 1002)) {
                  window.setTimeout(() => trigger("Hubs"), 100);
                }
              }}
              hubsController={
                <Controller
                  control={control}
                  name={"Hubs"}
                  as={<AgentAccess />}
                  error={_.get(errors, "Hubs")}
                  className={`${
                    isAgent() && _.includes(getValues("Roles"), 1002)
                      ? ""
                      : "u-hidden"
                  }`}
                />
              }
              {...rest}
            />
          )}
        />
        {renderFooter()}
      </Form>
    </>
  );
};
