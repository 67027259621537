import * as React from "react";
import { reaction } from "mobx";
import {
  CreateRequestedUserModalState,
  RequestedUserVM,
  FormError
} from "./interfaces";
import * as imageTemplate from "Account/partials/image.html";
import {
  Card,
  Form,
  Input,
  Checkbox,
  Button,
  DropdownItemProps
} from "semantic-ui-react";
import { Dropdown } from "Component";
import styles from "./index.less";
import {
  UserService,
  TranslationService,
  languages,
  SamskipNotify
} from "Services";
import "ts-nameof";
import {
  requiredValidator,
  notEmptyStringValidator,
  validateAll
} from "../../shared/validators/validators";
import * as _ from "lodash";

declare var GlobalConfig: GlobalConfig;
const urlParams = new URLSearchParams(window.location.search);

export default class CreateRequestedUserModal extends React.Component<
  any,
  CreateRequestedUserModalState
> {
  state: CreateRequestedUserModalState = {
    token: "",
    password: "",
    passwordAgain: "",
    emailNotificationChecked: false,
    cellphoneNotificationChecked: false,
    userAgreementChecked: false,
    language: "",
    translationOptions: [] as DropdownItemProps[],
    matchingPasswordError: false,
    formErrors: [
      {
        key: "password",
        validators: [requiredValidator, notEmptyStringValidator],
        value: false
      },
      {
        key: "passwordAgain",
        validators: [requiredValidator, notEmptyStringValidator],
        value: false
      }
    ] as FormError[]
  };

  componentDidMount() {
    UserService.clearAuthentication();
    this.spawnImage();

    reaction(
      () => TranslationService.getSelectedLanguage(),
      () => this.setState({})
    );

    this.setState({
      token: urlParams.get("token")
    });

    TranslationService.getAvailableTranslations().then((transData: any[]) => {
      const translations = [] as any;

      for (let i = 0; transData.length > i; i += 1) {
        translations.push(transData[i].Language);
      }

      this.createTranslationOptions(translations);
    });
  }

  createTranslationOptions = (list: any[]) => {
    this.setState({
      translationOptions: list.map((item: any, i: number) => {
        return {
          key: i,
          text: item,
          value: item
        };
      })
    });
  };

  onChange = (object: any) => {
    this.setState(prevState => ({ ...prevState, ...object }));
  };

  toggleCheck = (property: string) => {
    const newValue = !this.state[property];
    this.setState(prevState => ({
      ...prevState,
      [property]: newValue
    }));
  };

  onLanguageSelected = (language: string) => {
    this.setState({ language });
  };

  handleValidation = (key: any) => {
    // Validate that the passwords are matching
    if (this.state.password !== this.state.passwordAgain) {
      this.setState({ matchingPasswordError: true });
    } else {
      this.setState({ matchingPasswordError: false });
    }
    this.setState(prevState => {
      const formErrors = prevState.formErrors.map(
        (item: FormError, i: number) => {
          if (i === _.findIndex(prevState.formErrors, ["key", key])) {
            item.value = !validateAll(item.validators, _.get(this.state, key));
          }
          return item;
        }
      );
      return {
        ...prevState,
        formErrors
      };
    });
  };

  // Disables error handling for input field that has focus
  onDisableError = (name: any) => {
    this.setState(prevState => {
      const formErrors = prevState.formErrors.map(
        (item: FormError, i: number) => {
          if (i === _.findIndex(prevState.formErrors, ["key", name])) {
            item.value = false;
          }
          return item;
        }
      );
      return {
        ...prevState,
        formErrors
      };
    });
  };

  hasError = (propName: string) => {
    const formError = this.state.formErrors.find(item => item.key === propName);
    if (formError) {
      return formError.value;
    }
    if (this.state.matchingPasswordError) {
      return true;
    }
    return false;
  };

  displayErrorText = (value: any, formErrors: any) => {
    const error = formErrors.find((item: any) => item.value === true);

    if (value) {
      return (
        <div className="error-text">
          <p>
            <span>{languages("ERROR_PASSWORD_MISMATCH")}</span>
          </p>
        </div>
      );
    }

    if (!value && error) {
      if (error.value) {
        return (
          <div className="error-text">
            <p>
              <span>{languages("ERROR_PASSWORD_REQUIRED")}</span>
            </p>
          </div>
        );
      }
    }
  };

  validateForm = () => {
    const { matchingPasswordError, formErrors } = this.state;

    if (matchingPasswordError) {
      return false;
    }

    const error = formErrors.find((item: any) => item.value === true);
    if (error) {
      return false;
    }

    return true;
  };

  onSubmit = () => {
    const formData = this.state;
    const requestedUserVM: RequestedUserVM = {
      Password: formData.password,
      EmailNotification: formData.emailNotificationChecked ? "Y" : "N",
      CellphoneNotification: formData.cellphoneNotificationChecked ? "Y" : "N",
      Language: formData.language,
      TermsAccepted: formData.userAgreementChecked,
      Token: this.state.token
    };

    if (!this.validateForm()) {
      SamskipNotify.displayWarning(
        languages("ERROR_INVALID_FORM_INPUT"),
        languages("LABEL_ERROR")
      );
      return;
    }

    UserService.setRequestedUser(requestedUserVM)
      .then((data: string) => {
        if (data === "User Updated") {
          SamskipNotify.displaySuccess(languages("LABEL_SUCCESS"));
        } else if (data === "bad request") {
          SamskipNotify.displayError(languages("LABEL_FAILURE"));
        }
      })
      // Redirect to login page after 1 second
      .then(() => {
        setTimeout(() => {
          window.location.replace(GlobalConfig.ENV.ServiceWeb);
        }, 1000);
      })
      .catch((err: any) => {
        SamskipNotify.displayError(languages("ERROR_INTERNAL_SERVER_ERROR"));
      });
  };

  createUser = () => {
    const {
      emailNotificationChecked,
      cellphoneNotificationChecked,
      userAgreementChecked,
      translationOptions,
      language,
      matchingPasswordError,
      formErrors
    } = this.state;

    return (
      <Card.Content className="contentCreate">
        <Card.Header className="darker">
          {languages("LABEL_CREATEUSER")}
        </Card.Header>
        <Card.Description>
          <Form>
            <Form.Group>
              <label className="col-sm-2 control-label">
                {languages("LABEL_PASSWORD")}
              </label>
              <Input
                type="password"
                name="password"
                style={{ height: "34px" }}
                onChange={(e: any, data: any) =>
                  this.onChange({
                    [data.name]: data.value
                  })
                }
                onBlur={() =>
                  this.handleValidation(
                    "password"
                  )
                }
                onFocus={() =>
                  this.onDisableError(
                    "password"
                  )
                }
                error={
                  this.hasError(
                    "password"
                  ) || matchingPasswordError
                }
                autoFocus
              />
            </Form.Group>
            <Form.Group style={{ marginBottom: "-10px" }}>
              <label className="col-sm-2 control-label">
                {languages("LABEL_PASSWORDAGAIN")}
              </label>
              <Input
                type="password"
                name="passwordAgain"
                style={{ height: "34px" }}
                onChange={(e: any, data: any) =>
                  this.onChange({
                    [data.name]: data.value
                  })
                }
                onBlur={() =>
                  this.handleValidation(
                    "passwordAgain"
                  )
                }
                onFocus={() =>
                  this.onDisableError(
                    "passwordAgain"
                  )
                }
                error={
                  this.hasError(
                    "passwordAgain"
                  ) || matchingPasswordError
                }
              />
            </Form.Group>
            {this.displayErrorText(matchingPasswordError, formErrors)}
            <Form.Group style={{ marginTop: "10px" }}>
              <label className="col-sm-2 control-label">
                {languages("LABEL_NOTIFICATION")}
              </label>
              <Checkbox
                label={languages("LABEL_SEND_NOTIFICATIONS_EMAIL")}
                id="email"
                checked={emailNotificationChecked}
                onChange={() =>
                  this.toggleCheck(
                    "emailNotificationChecked"
                  )
                }
              />
              <Checkbox
                label={languages("LABEL_SEND_NOTIFICATIONS_CELLPHONE")}
                id="cellphone"
                checked={cellphoneNotificationChecked}
                onChange={() =>
                  this.toggleCheck(
                    "cellphoneNotificationChecked"
                  )
                }
              />
            </Form.Group>
            <Form.Group>
              <label className="col-sm-2 control-label">
                {languages("LABEL_LANGUAGE")}
              </label>
              <Dropdown
                className="languageDropdown"
                placeholder={languages("LABEL_SELECT_LANGUAGE")}
                options={translationOptions}
                onChange={(value: any) => {
                  this.onLanguageSelected(value);
                }}
                value={language}
              />
            </Form.Group>
            <Form.Group>
              <label className="col-sm-2 control-label">
                {languages("LABEL_USER_AGREEMENT")}
              </label>
              <Checkbox
                label={languages("LABEL_USER_AGREEMENT_CHECK")}
                id="user_agreement"
                checked={userAgreementChecked}
                onChange={() =>
                  this.toggleCheck(
                    "userAgreementChecked"
                  )
                }
              />
              <div className="user-agreement">
                <i className="fa fa-download"></i>
                <a
                  href={this.getLinkUrl()}
                  target="_blank"
                  dangerouslySetInnerHTML={{
                    __html: languages("LABEL_DOWNLOAD_USER_AGREEMENT")
                  }}
                />
              </div>
            </Form.Group>
            <Button
              type="submit"
              primary
              floated="right"
              onClick={this.onSubmit}
              disabled={
                !(this.validateForm() && this.state.userAgreementChecked)
              }
            >
              {languages("LABEL_CREATEUSER")}
            </Button>
          </Form>
        </Card.Description>
      </Card.Content>
    );
  };

  // Spawn background image
  spawnImage = () => {
    const imageEl = document.getElementById("image-wrapper");

    if (imageEl) imageEl.innerHTML += imageTemplate;
  };

  getLinkUrl = (): string => {
    if (TranslationService.getSelectedLanguage() === "is") {
      return "https://www.samskip.is/wp-content/uploads/2019/10/notendasamningur_thjonustuvefur.pdf";
    }
    return "https://www.samskip.is/wp-content/uploads/2019/10/notendasamningur_thjonustuvefur_en.pdf";
  };

  render() {
    return (
      <Card className={styles.wrapper} centered>
        {this.createUser()}
      </Card>
    );
  }
}
