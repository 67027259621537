import { UIRouter, StateService, Transition } from "@uirouter/core";
import * as angular from "angular";
import { autorun } from "mobx";
import { encode } from "jwt-simple";

import { USERWEBSETTINGS } from "Constants/UserWebSettingsConstants";
import { $uiRouter, $uibModal } from "Bootstrap/angular";
import {
  UserService,
  SamskipNotify,
  UserManagerService,
  TranslationService,
  languages
} from "Services";
import { UserStore } from "Stores";
import { UtilityFunctions, ModalUtilities } from "Utilities";
import EmailService from "Services/EmailService";
import { USER_ROLES } from "Constants/UserRoles";
import { USER_CREATION_ACTIONS } from "Constants/UserCreationConstants";
import { $filter } from "ngimport";
import GeneralLoader from "Utilities/GeneralLoader";

declare var GlobalConfig: GlobalConfig;
declare var $: any;

function userManagerController($scope: any, $transition$: Transition) {
  const $state: StateService = $uiRouter.stateService;
  const currentState: string = $transition$.to().name || "";
  const currentStateParams: any = $transition$.params();

  const selectedCompany = UserService.getSelectedCompany();

  UtilityFunctions.setListSizeSmall();
  UtilityFunctions.filterSlideControl(
    "js-Sidebar-triggerFilter",
    "js-Sidebar",
    "LABEL_OPEN_FILTER",
    "LABEL_CLOSE_FILTER"
  );
  UtilityFunctions.catToggle();

  // Filters Selected
  $scope.initFilter = function initFilter() {
    const Filter: any = {};

    Filter.query = { value: "", name: "Leit", translate: "LABEL_WITHSEARCH" };
    Filter.Status = {
      value: "*",
      name: "Staða",
      translate: "LABEL_STATUS",
      locked: {
        value: "*",
        name: "Staða",
        translate: ""
      },
      disabled: {
        value: "*",
        name: "Staða",
        translate: ""
      },
      pending: {
        value: "*",
        name: "Staða",
        translate: ""
      }
    };
    Filter.UserType = {
      value: "*",
      name: "Tegund notanda",
      translate: "LABEL_USERTYPE"
    };
    Filter.on = false;
    Filter.SearchString = null;
    Filter.inCompany = {
      value: false,
      name: "Núverandi fyrirtæki",
      translate: "LABEL_INCOMPANY"
    };
    Filter.SubSys = {
      value: GlobalConfig.subSys,
      name: "Kerfi",
      translate: "LABEL_SYSTEM"
    };

    return Filter;
  };
  $scope.Filter = $scope.initFilter();
  $scope.FilterBase = $scope.initFilter();

  autorun(() => {
    const listViewSize = UserService.getWebSettingObservable(
      USERWEBSETTINGS.SelectedListViewSize
    );
    if (!listViewSize) return;
    $scope.listViewSize = UtilityFunctions.getListViewSizeClass(
      listViewSize.StringValue
    );
  });

  $scope.isGlobalAdmin = function isGlobalAdmin() {
    return UserService.isGlobalAdmin();
  };

  $scope.companies = ["Husbyg", "Natols", "Sam"];
  $scope.systems = [
    { name: "Samskip", subsys: 1045, sitesubsys: GlobalConfig.subSys },
    { name: "Jónar Transport", subsys: 1044, sitesubsys: 1049 }
  ];
  $scope.users = undefined;
  $scope.selectedUsers = [];
  $scope.webroles = [];
  $scope.currentUser = UserService.getUserProfile();
  $scope.multiActionSelected = [];
  $scope.numberOfSelected = $scope.multiActionSelected.length;
  $scope.isEmployee = false;
  $scope.showUserRequestModal = false;
  $scope.pendingUserRequestEmailMap = {};

  $scope.reverse = false;

  // Pagination
  $scope.paginator = {};
  $scope.paginator.currentPage = 1;
  $scope.paginator.maxSize = 6;
  $scope.itemsPerPage = 25;

  // UserCreationModal
  $scope.showUserCreationModal = false;

  /**
   * UserModal functionality -- Shows information for a single user
   * @param  {obj}    user A user object describing a single user
   * @param  {USER_CREATION_ACTIONS}   action The action the modal is supposed to perform
   **********************************************************************/
  function showModal(user: any, action: USER_CREATION_ACTIONS) {
    $scope.selectedUser = user;
    $scope.modalAction = action;
    $scope.showUserCreationModal = true;
  }

  $scope.createUser = function createUser() {
    showModal(null, USER_CREATION_ACTIONS.CREATE);
  };

  $scope.closeUserCreationModal = function closeUserCreationModal(
    submitted: boolean
  ) {
    $scope.showUserCreationModal = false;
    if (submitted) {
      $scope.paginator.changePage($scope.paginator.currentPage, true);
    }
    $scope.$digest();
  };

  $scope.loadUser = function loadUser(user: any) {
    showModal(user, USER_CREATION_ACTIONS.EDIT);
  };

  $scope.userRequest = function userRequest() {
    showModal(null, USER_CREATION_ACTIONS.REQUEST);
  };

  $scope.hasAccessToModal = function hasAccessToModal() {
    const user = UserStore.userProfile;

    if (user) {
      if (
        user.Roles.find(
          (role: WebRole) => role.RoleID === USER_ROLES.USERMANAGER
        )
      ) {
        return true;
      }
    }

    if (UserService.isEmployee()) {
      return true;
    }
  };

  // ****************** UserModal functionality ends **************************/

  $scope.filters = function filters(item: UserViewModel): boolean {
    let addItem = true;

    // SubSys
    if (
      $scope.Filter.SubSys.value &&
      item.SubSys !== $scope.Filter.SubSys.value
    ) {
      addItem = false;
    }

    // Type
    if (
      $scope.Filter.UserType.value !== "*" &&
      item.UserType !== $scope.Filter.UserType.value
    ) {
      addItem = false;
    }

    // Status
    if (
      $scope.Filter.Status.value !== "*" &&
      (item.Disabled !== $scope.Filter.Status.disabled.value ||
        item.Locked !== $scope.Filter.Status.locked.value ||
        item.Pending !== $scope.Filter.Status.pending.value)
    ) {
      addItem = false;
    }

    return addItem;
  };

  $scope.paginator.changePage = function changePage(
    newPage: any,
    getList: boolean
  ) {
    if (getList === true) {
      $scope.getList(newPage);
    }

    $scope.paginator.currentPage = newPage;
  };

  $scope.$watch(
    "Filter",
    (newVal: any, oldVal: any) => {
      if (angular.equals($scope.Filter, $scope.FilterBase)) {
        $(".js-ContentHeader-clearFilters").hide();
      } else {
        $(".js-ContentHeader-clearFilters").show();
      }
    },
    true
  );

  $scope.$watch(
    "Filter.inCompany.value",
    (newVal: any, oldVal: any) => {
      $scope.getList();
    },
    true
  );

  if ($scope.currentUser) {
    UserManagerService.getWebRoles(
      $scope.currentUser.User.ID,
      $scope.currentUser.Access.SubSys
    ).then((data: WebRole[]) => {
      $scope.webroles = data;
    });
  }

  $scope.StatusCheck = function StatusCheck() {
    if ($scope.Filter.Status.value === "*") {
      $scope.Filter.Status.disabled.value = "*";
      $scope.Filter.Status.locked.value = "*";
      $scope.Filter.Status.pending.value = "*";
      $scope.Filter.Status.value = "*";
    } else if ($scope.Filter.Status.value === "disabled") {
      $scope.Filter.Status.disabled.value = "Y";
      $scope.Filter.Status.locked.value = "N";
      $scope.Filter.Status.pending.value = "N";
      $scope.Filter.Status.value = "disabled";
    } else if ($scope.Filter.Status.value === "locked") {
      $scope.Filter.Status.disabled.value = "N";
      $scope.Filter.Status.locked.value = "Y";
      $scope.Filter.Status.pending.value = "N";
      $scope.Filter.Status.value = "locked";
    } else if ($scope.Filter.Status.value === "active") {
      $scope.Filter.Status.disabled.value = "N";
      $scope.Filter.Status.locked.value = "N";
      $scope.Filter.Status.pending.value = "N";
      $scope.Filter.Status.value = "active";
    } else if ($scope.Filter.Status.value === "pending") {
      $scope.Filter.Status.disabled.value = "Y";
      $scope.Filter.Status.locked.value = "N";
      $scope.Filter.Status.pending.value = "Y";
    } else {
      $scope.Filter.Status.disabled.value = "*";
      $scope.Filter.Status.locked.value = "*";
      $scope.Filter.Status.pending.value = "*";
      $scope.Filter.Status.value = "*";
    }
  };

  UtilityFunctions.largeSpinner("largeSpinner");

  // populate users from web api
  $scope.getList = function getList() {
    if ($scope.inListAPICall) return;
    $(".js-DisplayList").fadeTo(200, 0);
    $(".loaderUsers").fadeTo(400, 1);
    $scope.users = [];

    $scope.inListAPICall = true;
    GeneralLoader.increase();
    UserManagerService.getUserList(
      $scope.Filter.query.value,
      $scope.Filter.inCompany.value
    )
      .then((data: UserViewModel[]) => {
        $scope.inListAPICall = false;
        $(".loaderUsers")
          .clearQueue()
          .fadeTo(400, 0, () => {
            $(".js-DisplayList").fadeTo(500, 1, () => {
              $(".js-ListPage-box").trigger("heightChange");
            });
          });
        $(".js-Tile-wrapper").fadeTo(500, 1, () => {
          $(".js-ListPage-box").trigger("heightChange");
        });
        $scope.users = data;
      })
      .finally(() => {
        GeneralLoader.decrease();
      });
  };

  // get text for users status
  $scope.getStatusLabel = function getStatusLabel(user: UserViewModel) {
    if (user.Disabled === "Y" && user.Pending === "Y") {
      return "LABEL_PENDING_CONFIRMATION";
    }
    if (user.Disabled === "Y") {
      return "LABEL_INACTIVE";
    }
    if (user.Locked === "Y") {
      return "LABEL_LOCKED";
    }

    return "LABEL_ACTIVE";
  };

  $scope.getStatusLabelClass = function getStatusLabelClass(
    user: UserViewModel
  ) {
    if (user.Disabled === "Y" && user.Pending === "Y") {
      return "u-colorOrange";
    }
    if (user.Disabled === "Y" || user.Locked === "Y") {
      return "u-colorRed";
    }

    return "u-colorGreen";
  };

  $scope.resendUserRequetsEmail = function resendUserRequetsEmail(
    userID: number,
    userName: string
  ) {
    const modalInstance = UtilityFunctions.confirmModal(
      "TEXT_CONFIRM_RESEND_CONFIRMATION_EMAIL",
      TranslationService.translate("TEXT_RESEND_CONFIRMATION_EMAIL", {
        user: userName
      }),
      "LABEL_CANCEL",
      "LABEL_PROCEED"
    );
    modalInstance.then(() => {
      $scope.pendingUserRequestEmailMap[userID] = true;
      UserManagerService.sendUserRequestEmail(userID)
        .then(() => {
          $scope.pendingUserRequestEmailMap[userID] = false;
          SamskipNotify.displaySuccess(
            TranslationService.translate("TEXT_RESENTUSERREQUESTEMAIL", {
              user: userName
            })
          );
        })
        .catch(() => {
          SamskipNotify.displayError(languages("ERROR_FAILED_SEND_EMAIL"));
          $scope.pendingUserRequestEmailMap[userID] = false;
        });
    });
  };

  $scope.hasPendingEmail = function hasPendingEmail(userID: number) {
    return $scope.pendingUserRequestEmailMap[userID] === true;
  };

  /* -------------- Ordering ----------------*/

  $scope.selectedPredicate = {
    pred: "UserName",
    name: "Nafn",
    translate: "TEXT_NAME"
  };

  // Filters array
  $scope.orderDropdownTypes = [
    { pred: "UserName", name: "Nafn", translate: "TEXT_NAME" },
    { pred: "Created", name: "Stofnaður", translate: "LABEL_CREATED" },
    {
      pred: "LastLogin",
      name: "Síðast tengdur",
      translate: "LABEL_LASTCONNECTED"
    },
    { pred: "Disabled", name: "Staða", translate: "LABEL_STATUS" },
    { pred: "Company", name: "Fyrirtæki", translate: "LABEL_COMPANY" }
  ];

  const orderBy = $filter("orderBy");
  // For sorting users
  $scope.order = function order() {
    $scope.users = orderBy(
      $scope.users,
      $scope.selectedPredicate.pred,
      $scope.reverse
    );
  };

  $scope.setPredicate = function setPredicate(pred: string) {
    $scope.selectedPredicate = pred;
    $scope.reverse = false;
  };

  $scope.removeFilter = function removeFilter(filter: any) {
    angular.forEach($scope.Filter, (value, key) => {
      if (
        typeof filter === "object" &&
        typeof value === "object" &&
        value != null &&
        filter.name === value.name
      ) {
        angular.forEach($scope.initFilter(), initValue => {
          if (initValue != null && initValue.name === value.name) {
            $scope.Filter[key] = initValue;
          }
        });
      }
    });
  };

  $scope.resetAllFilters = function resetAllFilters() {
    $scope.Filter = $scope.initFilter();
  };

  $scope.getList();
}

userManagerController.$inject = ["$scope", "$transition$"];
export default userManagerController;
