import React, { Component } from "react";
import { UserManagerService } from "Services";
import _ from "lodash";
import { Input } from "Component";

enum UsernameInputStatus {
  PRISTINE = 0,
  INVALID = -1,
  VALID = 1
}

export default class UsernameInput extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      status: UsernameInputStatus.PRISTINE
    };
    this.checkIfExists = _.debounce(this.checkIfExists, 500);
  }

  checkIfExists = (value: string) => {
    this.setState({ loading: true });
    UserManagerService.loginIdIsTaken(value)
      .then(data => {
        if (this.props.value.value !== value) {
          // Handles promises that resolve after the input is cleared
          return;
        }
        if (data) {
          this.setState({ status: UsernameInputStatus.INVALID });
        } else {
          this.setState({ status: UsernameInputStatus.VALID });
        }
        this.props.onChange({ value, exists: data });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleChange = (value: string) => {
    this.props.onChange({ value, exists: false });
    if (value === "") {
      this.setState({ loading: false, status: UsernameInputStatus.PRISTINE });
    } else {
      this.checkIfExists(value);
    }
  };

  render = () => {
    const { loading, status } = this.state;
    const { onChange, value, disabled, ...rest } = this.props;

    return (
      <Input
        onChange={(value: string) => this.handleChange(value)}
        loading={loading}
        disabled={disabled}
        icon={
          status !== UsernameInputStatus.PRISTINE && !disabled
            ? {
                name: status === UsernameInputStatus.VALID ? "check" : "times",
                className: status === UsernameInputStatus.VALID ? "yes" : "no"
              }
            : { name: "", className: "u-posAbsolute" } // Weird workaround to be able to show the spinner when no icon is present
        }
        value={value.value}
        {...rest}
      />
    );
  };
}
