import React, { useState, useEffect } from "react";
import { Modal } from "Component";
import { languages } from "Services";
import UserModalForm from "./UserModalForm";
import { USER_CREATION_ACTIONS } from "Constants/UserCreationConstants";
import styles from "./index.less";

interface UserModalProps {
  open: boolean;
  onClose: Function;
  onSubmit: Function;
  user: any;
  action: USER_CREATION_ACTIONS;
}

export default (props: UserModalProps) => {
  const [open, setOpen] = useState(false);

  const { onClose, user, action } = props;

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const close = (submitted: boolean) => {
    setOpen(false);
    onClose(submitted);
  };

  const getTitle = () => {
    switch (action) {
      case USER_CREATION_ACTIONS.CREATE:
        return "LABEL_CREATEUSER";
      case USER_CREATION_ACTIONS.EDIT:
        return "LABEL_CHANGEUSER";
      case USER_CREATION_ACTIONS.REQUEST:
        return "LABEL_CREATE_USER_REQUEST";
      default:
        return "LABEL_CREATEUSER";
    }
  };

  const renderHeader = () => {
    return (
      <>
        <span className="title">{languages(getTitle())}</span>
        <a className="close" onClick={() => close(false)}>
          <i className="fa fa-times"></i>
        </a>
      </>
    );
  };

  return (
    <Modal
      open={open}
      header={renderHeader()}
      onClose={() => close(false)}
      content={<UserModalForm onClose={close} user={user} action={action} />}
      closeOnDimmerClick={false}
    />
  );
};
