import React, { useState, useEffect } from "react";
import { languages } from "Services";
import {
  Button,
  Grid,
  GridColumn as Column,
  GridRow as Row,
  Icon
} from "semantic-ui-react";
import _ from "lodash";
import styles from "../index.less";

interface RolesProps {
  roles: WebRole[];
  showEmployeeRoles: boolean;
  selectedRoles: number[];
  error: boolean;
  onChange: Function;
  hubsController: any;
}

export default (props: RolesProps) => {
  const [employeeRoles, setEmployeeRoles] = useState([] as WebRole[]);
  const [customerRoles, setCustomerRoles] = useState([] as WebRole[]);

  useEffect(() => {
    const [e, c] = _.partition(
      props.roles,
      item => item.Employee === "1" && item.Customer === "0"
    );
    setEmployeeRoles(_.sortBy(e, item => item.RoleDescription));
    setCustomerRoles(_.sortBy(c, item => item.RoleDescription));
  }, [props.roles]);

  const renderRow = (item: WebRole) => {
    const isSelected = _.includes(props.selectedRoles, item.RoleID);
    return (
      <Row className="role" key={item.RoleID}>
        <div className="info">
          <span
            className="description"
            title={languages(`ROLE_${item.RoleName}`)}
          >
            {languages(`ROLE_${item.RoleName}`)}
          </span>
          <span className="name" title={item.RoleName}>
            {item.RoleName}
          </span>
        </div>
        <Button
          onClick={(event: any) => onChange(event, item.RoleID)}
          className={isSelected ? "yes" : "no"}
        >
          <Icon name={isSelected ? "check" : "times"} />
          {isSelected ? "Yes" : "No"}
        </Button>
        {item.RoleID === 1002 && props.hubsController}
      </Row>
    );
  };

  const onChange = (event: any, value: number) => {
    event.preventDefault();
    const roles = _.clone(props.selectedRoles);
    const index = roles.findIndex(item => item === value);
    if (index < 0) {
      roles.push(value);
    } else {
      roles.splice(index, 1);
    }
    props.onChange(roles);
  };

  return (
    <Grid className={styles.roles} stackable>
      <Row columns="equal">
        <Column>
          <label className="label">{languages("LABEL_PUBLICACCESS")}</label>
          <Grid>
            {customerRoles.length > 0 &&
              customerRoles.map(item => renderRow(item))}
          </Grid>
        </Column>
        {props.showEmployeeRoles && (
          <Column>
            <label className="label">{languages("LABEL_EMPLOYEEACCESS")}</label>
            <Grid>
              {employeeRoles.length > 0 &&
                employeeRoles.map(item => renderRow(item))}
            </Grid>
          </Column>
        )}
      </Row>
    </Grid>
  );
};
